<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-03-04 10:10:43
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 16:36:32
 * @FilePath: /impact-iotos-console/src/views/drive/task/taskCodeMirror.vue
-->

<template>
  <div class="task-code-mirror">
    <div class="btn-groups">
      <div class="btn-normal1" @click="handleTop">
        <img src="@/assets/up.png">
        滚动到顶部
      </div>
      <div class="btn-normal1" @click="handleBottom">
        <img src="@/assets/down.png">
        滚动到底部
      </div>
      <div class="btn-normal" @click="reset">
        清空
      </div>
    </div>
    <br>
    <div ref="containerRef" class="task-code" @mouseover="() => isRun = false" @mouseleave="() => isRun = true">
      <div class="left-scroller" :aria-hidden="true">
        <span :aria-hidden=true v-for="( item, index ) of  list " :key="index">{{ index + 1 }}</span>
      </div>
      <div>
        <div class="item-container" v-for="( item, index ) of  list " :key="index">
          <!-- DEBUG -->
          <div v-if="item && item.text && item.type === 1" style="color:#164">{{ item.text }}</div>
          <!-- INFO -->
          <div v-if="item && item.text && item.type === 2">{{ item.text }}</div>
          <!-- WARN -->
          <div v-if="item && item.text && item.type === 3" style="color:#e6a23c">{{ item.text }}</div>
          <!-- ERROR -->
          <div v-if="item && item.text && item.type === 4" style="color:#f56c6c">{{ item.text }}</div>
          <div v-if="item && item.text && item.type === 5">{{ item.text }}</div>
          <div v-if="item && !item.text">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
const containerRef = ref(null);
const isRun = ref(true);
const list = ref([]);
const reset = () => {
  list.value = [];
}
const add = (v) => {
  if (list.value.length > 0) {
    if (!list.value[0].text) {
      list.value = [];
    }
  }
  const DEBUG = / DEBUG /g;
  const INFO = / INFO /g;
  const WARN = / WARN /g;
  const ERROR = / ERROR /g;
  if (v.match(DEBUG)) {
    const obj = {
      type: 1,
      text: v
    }
    list.value.push(obj);
  } else if (v.match(INFO)) {
    const obj = {
      type: 2,
      text: v
    }
    list.value.push(obj);
  } else if (v.match(WARN)) {
    const obj = {
      type: 3,
      text: v
    }
    list.value.push(obj);
  } else if (v.match(ERROR)) {
    const obj = {
      type: 4,
      text: v
    }
    list.value.push(obj);
  } else {
    const obj = {
      type: 5,
      text: v
    }
    list.value.push(obj);
  }
  if (isRun.value) {
    handleBottom()
  }
}
/**
 * 滚动到顶部
 */
const handleTop = () => {
  if (containerRef.value) {
    containerRef.value.scrollTop = 0;
  }
}
/**
 * 滚动到底部
 */
const handleBottom = () => {
  if (containerRef.value) {
    containerRef.value.scrollTop = containerRef.value.scrollHeight - containerRef.value.clientHeight;
  }
}
defineExpose({ add, reset });
</script>


<style lang="less" scoped>
.btn-normal1 {
  display: flex;
  align-items: center;
  color: #AFB9CB;
  font-weight: lighter;
  margin-left: 40px;
  img {
    width: 14px;
    height: 14px;
    margin-right: 3px;
  }
}

.task-code-mirror {
  position: relative;
}

.btn-groups {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  top: -60px;

  &>div {
    margin-right: 10px;
  }
}

.task-code {
  width: 100%;
  height: 400px;
  overflow: scroll;
  display: flex;
  align-items: flex-start;
}

.left-scroller {
  &>span {
    box-sizing: border-box;
    display: block;
    width: 30px;
    height: 24px !important;
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    background: #eee;
  }

}

.item-container {
  box-sizing: border-box;
  padding: 0 5px;
  word-wrap: none;
  height: 24px !important;

  &>div {
    box-sizing: border-box;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: none !important;
    height: 24px !important;
    line-height: 24px;
  }
}

.btn-normal {
  width: 80px;
  height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #367CC5;
  line-height: 38px;
  color: #367CC5;
  text-align: center;
  margin-left: 40px;
  cursor: pointer;
}
</style>