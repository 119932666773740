<!--
 * @Description: 任务列表 - 运行详情
 * @Author: kecraft
 * @Date: 2024-02-28 15:28:10
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 13:24:16
 * @FilePath: /impact-iotos-console/src/views/drive/task/taskDetails.vue
-->

<template>
  <div class="task-details">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/drive/task/list' }">任务列表</el-breadcrumb-item>
        <el-breadcrumb-item>运行详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info-box">
      <div class="overview-item">
        <div class="overview-icon">
          <img src="@/assets/login/product.png" alt="">
        </div>
        <div class="overview-title">{{ taskName || "-" }}</div>
      </div>
      <div class="id-box">
        <div class="pk-conatiner">
          <div class="overview-pk">任务id：{{ id }}</div>
          <div class="copy-btn" @click="copyToClipboard(id)">复制</div>
        </div>
        <div class="btns">
          <div @click="handleStop" v-if="form && form.running">停止</div>
          <div @click="handleRun" v-else>启动</div>
          <div @click="handleReload">重启</div>
        </div>
      </div>

      <div class="info-container">
        <div class="info-item first-left ">
          <div class="item-left">驱动名称</div>
          <div>{{ form && form.driver && form.driver.name || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">驱动ID</div>
          <div>{{ form && form.driver && form.driver.id || "-" }}</div>
        </div>
        <div class="info-item first-left">
          <div class="item-left">驱动版本</div>
          <div>{{ form && form.driver && form.driver.version || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">运行状态</div>
          <div class="status-container" v-if="form && form.running">
            <div class="icon1"></div>
            <div>运行</div>
          </div>
          <div class="item-right-status" v-else>
            <div class="status-container">
              <div class="icon"></div>
              <div>停止</div>
            </div>
          </div>
        </div>
        <div class="info-item first-left">
          <div class="item-left">JVM参数</div>
          <div>{{ form && form.jvmArgs || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">连接地址</div>
          <div>{{ form && form.connectMqttEndpoint || "-" }}</div>

        </div>
        <div class="info-item first-left">
          <div class="item-left">环境变量</div>
          <div>{{ form && form.envArgs || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">进程pid</div>
          <div>{{ form && form.环境变量 || "-" }}</div>
        </div>
        <div class="info-item first-left">
          <div class="item-left">开始时间</div>
          <div>{{ form && form.startTime || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">停止时间</div>
          <div>{{ form && form.stopTime || "-" }}</div>
        </div>
        <div class="info-item first-left">
          <div class="item-left">是否自动启动</div>
          <div>{{ form && form.autoStart ? '是' : "否" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">网关设备ID</div>
          <div>{{ form && form.gatewayDevId || "-" }}</div>
        </div>
        <div class="info-item first-left">
          <div class="item-left">网关设备Pk</div>
          <div>{{ form && form.gatewayDevPk || "-" }}</div>
        </div>
        <div class="info-item">
          <div class="item-left">网关设备密钥</div>
          <div>{{ form && form.gatewayDevSecret || "-" }}</div>
        </div>
        <div class="info-item1  first-left">
          <div class="item-left">命令参数</div>
          <div>{{ form && form.args || "-" }}</div>
        </div>
      </div>
      <div>
        <div class="top-container">
          <div class="tabs-conatiner">
            <div v-for="i in tabs" :key="i" class="tab-item" :class="i !== selectIndex ? 'is_select' : ''"
              @click="handleTabChanged(i)">
              <div>{{ i }}</div>
              <div class="line" v-if="i === selectIndex"></div>
              <div class="line-none" v-else></div>
            </div>
          </div>
        </div>
        <taskCodeMirror ref="taskCodeMirrorRef" v-if="selectIndex === '实时日志'" />
        <div v-if="selectIndex === '历史日志'">
          <div class="search-btn" @click="getHistory">
            刷新
          </div>
          <Codemirror class="code" v-model="historyScript" :style="{ height: '360px' }" :extensions="extensions"
            :autofocus="false" :disabled="true" ref="codeMirrorRef1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { onMounted, ref, onBeforeUnmount, nextTick, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { copyToClipboard } from "@/hooks/common";
import { useRoute } from "vue-router";
import { ElLoading } from 'element-plus';
import api from "@/api/api"
import ws from "@/utils/webSocket";
import { javaWs } from "@/utils/config";
import taskCodeMirror from "./taskCodeMirror.vue";
const route = useRoute();
const historyScript = ref("");
const extensions = [javascript()];
const script = ref("");
const taskName = ref(null);
const id = ref(null);
const form = ref({});
let currentInstance = "";
const selectIndex = ref("实时日志");
const tabs = ["实时日志", "历史日志"];
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { name, taskId } = route.query;
  id.value = taskId;
  taskName.value = name;
  if (!id.value || !taskName.value) return
  getInfo();
  initWebSocket();
  getHistory();
})
const handleTabChanged = (v) => {
  if (v === selectIndex.value) return;
  selectIndex.value = v;
  if (v === "历史日志") {
    getHistory()
  }
}
const getInfo = () => {
  api.getTaskInfoById(id.value).then(res => {
    if (res.code === "0") {
      form.value = res.res.data
    }
  })
}
const getHistory = () => {
  api.getHistoryTaskLog(id.value, {
    skip: 0,
    size: 500,
  }).then(res => {
    if (res.code === "0") {
      const list = res.res.data;
      const history = []
      list.forEach(item => {
        history.push(item.content);
      })
      const str = history.join("\n");
      historyScript.value = str;
    }
  })
}

const handleStop = () => {
  ElMessageBox.confirm("确定停止该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.stopTask(id.value).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("停止成功!")
        getInfo()
      }
      loading.close();
    })
  })
}

const handleRun = () => {
  ElMessageBox.confirm("确定启动该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.startTask(id.value).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("启动成功!")
        getInfo()
      }
      loading.close();
    })
  })
}

const handleReload = () => {
  ElMessageBox.confirm("确定重启该任务吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const loading = ElLoading.service({
      lock: true,
      text: 'Loading',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    api.restartTask(id.value).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("重启成功!")
        nextTick(() => {
          currentInstance.proxy.$refs.taskCodeMirrorRef.reset();
        });
        getInfo()
      }
      loading.close();
    })
  })
}
const initWebSocket = () => {
  const token_url = process.env.VUE_APP_TOKEN
  let tokenstr = localStorage.getItem(token_url) || ""
  if (tokenstr) {
    tokenstr = tokenstr.split(" ")[1]
  }
  const url = javaWs + `?SUBSCRIBE=driver_task_log&CLIENT_ID=driver_task:${id.value}&TOKEN=${tokenstr}`;
  ws.initWebSocket(url);
  ws.sendSock("PING", wsMessage);
}
const wsMessage = (e) => {
  if (e && e.data) {
    script.value = "";
    try {
      const info = JSON.parse(e.data)
      script.value = info.data + "\n"
    } catch {
      script.value = e.data + "\n"
    }
    nextTick(() => {
      currentInstance.proxy.$refs.taskCodeMirrorRef.add(script.value);
    });
  }
}
onBeforeUnmount(() => {
  if (ws) {
    ws.websocketclose();
    console.log("关闭连接");
  }
})
</script>

<style lang="less" scoped>
.search-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #367CC5;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}

.tabs-conatiner {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #EEEEEE;

  .top-container {
    border-bottom: 1px solid #EEEEEE;
  }

  .tab-item {
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;
    margin-right: 30px;
    font-size: 18px;
    cursor: pointer;
    border: 3px solid transparent;
    // margin-bottom: 12px;
  }

  .line {
    margin-top: 12px;
    width: 100%;
    height: 3px;
    background: #367CC5;
  }

}

.btns {
  display: flex;
  align-items: center;

  div {
    width: 80px;
    height: 40px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #367CC5;
    line-height: 38px;
    color: #367CC5;
    text-align: center;
    margin-left: 10px;
    cursor: pointer;
  }
}

.id-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pk-conatiner {
  display: flex;
  align-items: center;
  // flex: 1;

  .overview-pk {
    font-size: 16px;
    line-height: 22px;
    color: #333;
  }

  .copy-btn {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 50px;
    color: #367CC5;
    cursor: pointer;
  }
}

.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
  width: 100%;
}

.task-details {
  box-sizing: border-box;
}

.info-box {
  box-sizing: border-box;
  padding: 20px 50px 0 50px;
  background: #fff;
}

.overview-item {
  // padding: 10px 0;
  display: flex;
  align-items: center;

  .overview-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .overview-left {
    display: flex;
    align-items: center;
  }

  .overview-icon {
    width: 18px;
    height: 20px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .overview-title {
    font-weight: 700;
    font-size: 20px;
  }

  .copy-btn {
    // flex: 1;
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 40px;
    color: #015ee0;
    cursor: pointer;
  }

  .copy-btn:hover,
  .copy-btn:active {
    background: rgba(1, 94, 224, .06);
  }
}

.info-container {
  margin-top: 15px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .first-left {
    border-left: 1px solid #DCE0EB;
  }

  &>div:nth-child(1) {
    border-top: 1px solid #DCE0EB;
  }

  &>div:nth-child(2) {
    border-top: 1px solid #DCE0EB;
  }

  .info-item1 {
    width: 100%;
    border-bottom: 1px solid #DCE0EB;
    border-right: 1px solid #DCE0EB;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &>div {
      box-sizing: border-box;
      padding-right: 5px;
      word-break: break-all;
    }

    .item-left {
      padding: 20px 0 20px 15px;
      font-size: 16px;
      color: #656775;
      background: #F6F7F9;
      border-right: 1px solid #DCE0EB;
    }


    &>div:first-child {
      padding-left: 30px;
      font-size: 14px;
      color: #656775;
      width: 17.5%;
    }

    &>div:last-child {
      padding-left: 30px;
      font-size: 14px;
      color: #333333;
      width: 82.5%;
    }
  }

  .info-item {
    width: 50%;
    border-bottom: 1px solid #DCE0EB;
    border-right: 1px solid #DCE0EB;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &>div {
      box-sizing: border-box;
      padding-right: 5px;
      word-break: break-all;
    }

    .item-left {
      padding: 20px 0 20px 15px;
      font-size: 16px;
      color: #656775;
      background: #F6F7F9;
      border-right: 1px solid #DCE0EB;
    }


    &>div:first-child {
      padding-left: 30px;
      font-size: 14px;
      color: #656775;
      width: 35%;
    }

    &>div:last-child {
      padding-left: 30px;
      font-size: 14px;
      color: #333333;
      width: 65%;
    }
  }


  .item-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
    }

    .copy-btn {
      text-align: center;
      display: inline-block;
      width: 60px;
      height: 20px;
      line-height: 20px;
      margin-right: 10px;
      color: #367CC5;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }
}

.edit-btn {
  float: right;
  margin-right: 20px;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}

.status-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgba(135, 140, 170, .5);
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(135, 140, 170, .5);
}

.icon::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(135, 140, 170, .5);
}

.icon1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #0eb463;
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(14, 180, 99, .5);
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0eb463;
}
</style>